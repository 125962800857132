import request from '@/utils/request'


export function clubSta(query) {
  return request({
    url: '/statistics/club/club/page',
    method: 'get',
    params: query
  })
}

